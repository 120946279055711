import React from "react";

const HeroBanner = () => {

    return (
        <div className="banner-section">
            <div className="max-w-7xl m-auto flex flex-wrap">
                <div className="masthead">
                    <div className="title">Couchbase Developer Tutorials</div>
                    <div className="text-lg font-light">Tutorials and resources to help developers learn how to use Couchbase's SDKs, 
                        Capella, and Couchbase Mobile. These SDKs support popular programming languages, including Java, Node.js, 
                        Python, .NET, Golang, as well as Swift, Android, Kotlin, and more.
                    </div>
                </div>
                <div className="flex justify-center align-center w-full">
                    <div className="ml-12 featured-img-wrap">
                    <img src="https://www.couchbase.com/developers/wp-content/uploads/sites/4/2023/03/Left-Angle-Bracket-Shape.svg" alt="" className="w-full"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroBanner;
