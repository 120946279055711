import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

const TutorialTypeFilter = ({
    selectedContentType,
    onSelectContentType,    
    contentOptions,
    contentLength,
    handleSearch,
    isTutorialTypeFilterOpen,
    setFiltersToogle
}) => {
    const handleOnClick = (e) => {
        if (e.target.value === 'All Content') {
            onSelectContentType('')
            handleSearch()
        } else {
            onSelectContentType(e.target.value)
            handleSearch()
        }
    }

    return (
        <div className="accordion-wrapper" id="tutorialFilterId">
            <Accordion
                allowZeroExpanded={true}
                preExpanded={[]}
            >
                <AccordionItem uuid="4778fe86-ec6c-11ec-8ea0-0242ac120002" dangerouslySetExpanded={isTutorialTypeFilterOpen}>
                    <AccordionItemHeading onClick={() =>
                    isTutorialTypeFilterOpen ? setFiltersToogle([false, false, false, false]) : setFiltersToogle([true, false, false, false])}>
                        <AccordionItemButton>TUTORIAL TYPE</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div
                            className="tutorials-type-filter"
                        >
                            <div className="tutorials-type-content"
                                style={{
                                    backgroundColor: selectedContentType === '' ? '#E0E0E0' : 'white',
                                }}
                            >
                                <span>
                                    {' '}
                                    <input
                                        readOnly
                                        value="All Content"
                                        className="tutorials-type-selector"
                                        onClick={handleOnClick}
                                        style={{
                                            backgroundColor: selectedContentType === '' ? '#E0E0E0' : 'white',
                                        }}
                                    />
                                </span>
                                <span className="tutorials-type-count">{contentLength}</span>
                            </div>
                        </div>
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                className="tutorials-type-filter"
                            >
                                <div className='tutorials-type-content'
                                    style={{
                                        backgroundColor:
                                            selectedContentType === item.value ? '#E0E0E0' : 'white',
                                    }}
                                >
                                    <span>
                                        {' '}
                                        <input
                                            readOnly
                                            value={item.value}
                                            className="tutorials-type-selector"
                                            onClick={handleOnClick}
                                            style={{
                                                backgroundColor:
                                                    selectedContentType === item.value ? '#E0E0E0' : 'white',
                                            }}
                                        />
                                    </span>
                                    <span className="tutorials-type-count">
                                        {item.count ? item.count : 0}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default TutorialTypeFilter
