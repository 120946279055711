import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const LanguageFilter = ({
  selectedLanguages,
  setSelectedLanguages,
  handleSearch,
  isLanguageFilterOpen,
  setFiltersToogle
}) => {
  const languages = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          distinct(field: frontmatter___sdk_language)
        }
      }
    `
  )

  const [showAll, setShowAll] = useState(true)
  const [languageOptions, setLanguageOptions] = useState(
    languages.allMarkdownRemark.distinct.map((language) => {
      return {
        value: language,
        label: language,
        checked: selectedLanguages.includes(language),
      }
    })
  )

  const handleShowAll = () => {
    if (!showAll) {
      setSelectedLanguages([])
      setLanguageOptions(
        languages.allMarkdownRemark.distinct.map((language) => {
          return {
            value: language,
            label: language,
            checked: false,
          }
        })
      )
    }
    setShowAll(!showAll)
    handleSearch()
  }

  const handleSelection = (e) => {
    setShowAll(false)
    let array = selectedLanguages
    if (e.target.checked) {
      array.push(e.target.value)
      setSelectedLanguages(array)
    } else {
      const index = array.indexOf(e.target.value)
      if (index !== -1) {
        array.splice(index, 1)
        setSelectedLanguages(array)
      }
    }

    setLanguageOptions(
      languageOptions.map((language) => {
        if (language.value === e.target.value) {
          return { ...language, checked: !language.checked }
        }
        return language
      })
    )

    if (selectedLanguages.length === 0) {
      setShowAll(true)
    }
    handleSearch()
  }

  return (
    <div className="accordion-wrapper" id="languageFilterId">
      <Accordion
        id="acc"
        allowZeroExpanded={true}
        preExpanded={[]}
      >
        <AccordionItem uuid="1c771fc4-ec6c-11ec-8ea0-0242ac120002" dangerouslySetExpanded={isLanguageFilterOpen}>
          <AccordionItemHeading onClick={() =>
          isLanguageFilterOpen ? setFiltersToogle([false, false, false, false]) : setFiltersToogle([false, true, false, false])
        }>
            <AccordionItemButton>LANGUAGE/SDK</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div>
              <label>
                Show All
                <input
                  value="show-all"
                  type="checkbox"
                  checked={showAll}
                  style={{ float: 'right' }}
                  onChange={handleShowAll}
                />
              </label>
            </div>
            {languageOptions.map((item, index) => (
              <div key={index}>
                <label>
                  {item.label}
                  <input
                    value={item.value}
                    type="checkbox"
                    checked={item.checked}
                    style={{ float: 'right' }}
                    onChange={handleSelection}
                  />
                </label>
              </div>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default LanguageFilter
