import React from 'react'
import { Link } from 'gatsby'
import '../../styles/modules/tutorials.scss'

const TutorialTiles = ({ tutorials, showCategories, gridTilesContainerClass='grid-tiles-container' }) => {

  const tutorialFilter = [
    { 'name': 'cloud', 'title': 'Cloud' },
    { 'name': 'sdk', 'title': 'SDKs' },
    { 'name': 'n1ql', 'title': 'N1QL/Data Modeling' },
    { 'name': 'mobile', 'title': 'Mobile' },
    { 'name': 'observability', 'title': 'Observability' },
    { 'name': 'other', 'title': 'Others' },
  ]

  return (
    <>
      {showCategories && tutorialFilter.map((f) => {

        const theseTuts = tutorials.filter(t => t.frontmatter.filter === f.name) 
        return (
          <div key={f.title} id={f.title} className='tutorial-section'>
            <h2>{f.title}</h2>
            <div className={gridTilesContainerClass}>
              {theseTuts.map((tutorial, idx) => {
                const key = `${tutorial.frontmatter.path.substring(1)}-${idx + 1}`
                return (
                  <Link to={`${tutorial.frontmatter.path}`} key={key} className='inline-block'>
                    <div className={`grid-tile-item ${key}`} key={key}>
                      <div className='tile-item--title'>
                        {tutorial.frontmatter.title}
                      </div>
                      <hr />
                      <div className='tile-item-description'>
                        <ul>
                          {tutorial.frontmatter.description.map((descriptionBullet, idx) => {
                            return (<li key={idx}>{descriptionBullet}</li>)
                          })}
                        </ul>
                      </div>
                      <hr />
                      <div className='tile-item--tags'>
                        {tutorial.frontmatter.tags.map((value, idx) => (
                          <div className='tags--tag' key={`${value}-${idx}`}>
                            {value}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        )

      })
      }
      {!showCategories &&
      <div>
        <div className={gridTilesContainerClass}>
          {tutorials.map((tutorial, idx) => {
            const key = `${tutorial.frontmatter.path.substring(1)}-${idx + 1}`
            return (
              <Link to={`${tutorial.frontmatter.path}`} key={key} className='inline-block'>
                <div className={`grid-tile-item ${key}`} key={key}>
                  <div className='tile-item--title'>
                    {tutorial.frontmatter.title}
                  </div>
                  <hr />
                  <div className='tile-item-description'>
                    <ul>
                      {tutorial.frontmatter.description.map((descriptionBullet, idx) => {
                        return (<li key={idx}>{descriptionBullet}</li>)
                      })}
                    </ul>
                  </div>
                  <hr />
                  <div className='tile-item--tags'>
                    {tutorial.frontmatter.tags.map((value, idx) => (
                      <div className='tags--tag' key={`${value}-${idx}`}>
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
      }
    </>
  )
}

export default TutorialTiles
