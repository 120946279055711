import React, { Fragment } from "react";

const LearnMoreComponent = () => {
    return (
        <Fragment>
            <div className=" learn-more-section">
                <div className="page--tutorials wrapper ">
                    <div className='pt-8 text-start pl-0'>
                        <h2 className="text-white">Learn more about Couchbase</h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-16 place-items-start">
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Join the community!</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                            We’re all hanging out on Discord and would love for you to join our conversations!</div>
                            <a href="https://discord.com/invite/K7NPMPGrPk"
                                className="learn-more-btn">Learn More
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Access our docs</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                            Here’s everything you need to start building with Couchbase Capella.
                            </div>
                            <a href="https://docs.couchbase.com/home/index.html"
                                className="learn-more-btn">Explore Documentation
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Get certified with Couchbase Academy</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                            Whether you’re managing Couchbase on premises, using CAO, using Couchbase Capella, or writing apps that use Couchbase, we have a certification for you.
                            </div>
                            <a href="https://www.couchbase.com/academy"
                                className="learn-more-btn">Get Certified
                            </a>
                        </div>
                        <div className="text-white my-10">
                            <div className="text-lg font-bold leading-6 mb-3.5">Stay sharp with our blog</div>
                            <div className="text-base font-normal leading-6 pb-5 mb-5">
                            News breaks first on our blog. Stay up to date on the Couchbase ecosystem and learn tips and tricks from our engineers, developer advocates, and partners.
                            </div>
                            <a href="https://www.couchbase.com/blog/"
                                className="learn-more-btn">Subscribe To Our Blog
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LearnMoreComponent;