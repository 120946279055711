import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'

const TechnologyFilter = ({
  selectedTechnology,
  setSelectedTechnology,
  tech,
  contentLength,
  handleSearch,
  isTechFilterOpen,
  setFiltersToogle
}) => {
  const technology = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          distinct(field: frontmatter___technology)
        }
      }
    `
  )

  const [selectedValue, setSelectedValue] = useState('')
  const [technologyOptions, setTechnologyOptions] = useState([])
  useEffect(() => {
    const techFreq = tech
      .flat()
      .reduce((acc, curr) => acc.set(curr, (acc.get(curr) || 0) + 1), new Map())
    const techCount = Object.fromEntries(
      [...techFreq.entries()].filter(([_, count]) => count >= 0)
    )
    setTechnologyOptions(
      technology.allMarkdownRemark.distinct.map((tech) => {
        return {
          value: tech,
          label: tech,
          count: techCount[tech],
        }
      })
    )

    setSelectedValue(selectedTechnology)
  }, [selectedTechnology, contentLength, technology, tech, selectedValue])

  const handleOnClick = (e) => {
    if (e.target.value === 'All Technologies') {
      setSelectedTechnology('')
      handleSearch()
    } else {
      setSelectedTechnology(e.target.value)
      handleSearch()
    }
  }

  return (
    <div className="accordion-wrapper" id="technologyFilterId">
      <Accordion
        allowZeroExpanded={true}
        allowMultipleExpanded={false}
        preExpanded={[]}
      >
        <AccordionItem uuid="4778fe86-ec6c-11ec-8ea0-0242ac120002" dangerouslySetExpanded={isTechFilterOpen}>
          <AccordionItemHeading onClick={() =>
          isTechFilterOpen ? setFiltersToogle([false, false, false, false]) : setFiltersToogle([false, false, false, true])}>
            <AccordionItemButton>TECHNOLOGY</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div
              className="technology-filter"
            >
              <div className="technology-content"
                style={{
                  backgroundColor: selectedValue === '' ? '#E0E0E0' : 'white',
                }}
              >
                <span>
                  {' '}
                  <input
                    readOnly
                    value="All Technologies"
                    className="technology-selector"
                    onClick={handleOnClick}
                    style={{
                      backgroundColor: selectedValue === '' ? '#E0E0E0' : 'white',
                    }}
                  />
                </span>
                <span className="technology-count">{contentLength}</span>
              </div>
            </div>
            {technologyOptions.map((item, index) => (
              <div
                key={index}
                className="technology-filter"
              >
                <div className='technology-content'
                  style={{
                    backgroundColor:
                      selectedValue === item.value ? '#E0E0E0' : 'white',
                  }}
                >
                  <span>
                    {' '}
                    <input
                      readOnly
                      value={item.value}
                      className="technology-selector"
                      onClick={handleOnClick}
                      style={{
                        backgroundColor:
                          selectedValue === item.value ? '#E0E0E0' : 'white',
                      }}
                    />
                  </span>
                  <span className="technology-count">
                    {item.count ? item.count : 0}
                  </span>
                </div>
              </div>
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default TechnologyFilter
