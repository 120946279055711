import React, { Fragment, useCallback, useState } from 'react';
import LanguageFilter from './LanguageFilter'
import TagFilter from "./TagFilter"
import TechnologyFilter from './TechnologyFilter';
import TutorialTypeFilter from "./TutorialTypeFilter"

const Filters = (props) => {
    const handleSearch = () => { }
    const setTags = (data) => {
        props.setSelectedTags([...data])
    }

    const setLanguages = (data) => {
        props.setSelectedLanguages([...data])
    }
    
    const bodyClickCallback = useCallback((e)=>{
        const target = Object.assign(e.target,{});
        target.className = target.className.trim(); 
        const laguageFilterAccordion = document.querySelector('#languageFilterId');       
        const tutorialsFilterAccordion = document.querySelector('#tutorialFilterId'); 
        const searchFilterAccordion = document.querySelector('#searchTagsFilterId');    
        const technologyFilterAccordion = document.querySelector('#technologyFilterId');       
        if((laguageFilterAccordion && laguageFilterAccordion.contains(target)) || (tutorialsFilterAccordion && tutorialsFilterAccordion.contains(target)) ||
        (searchFilterAccordion && searchFilterAccordion.contains(target)) || (technologyFilterAccordion && technologyFilterAccordion.contains(target))) {            
            return;  
        }             
        setFiltersToogle([false, false, false, false])
        document.body.removeEventListener('click',bodyClickCallback, true)            
        e.stopPropagation()    
    }, [])
    
    const [[isTutorialTypeFilterOpen, isLanguageFilterOpen, isTagFilterOpen, isTechFilterOpen], setFiltersToogle] = useState([false, false, false, false])
    if(isTutorialTypeFilterOpen || isLanguageFilterOpen || isTagFilterOpen || isTechFilterOpen){        
        document.body.addEventListener('click', bodyClickCallback, true);
    }  

    return (<Fragment>
        <TutorialTypeFilter
            selectedContentType={props.selectedContentType}            
            onSelectContentType={props.onSelectContentType}
            contentLength={props.quickstarts.length + props.learningPaths.length + props.tutorials.length}
            contentOptions={[{
                value: "Quickstart Guides", count: props.quickstarts.length
            }, {
                value: "Learning Paths", count: props.learningPaths.length
            }, {
                value: "Tutorials", count: props.tutorials.length
            }]}
            handleSearch={handleSearch}
            isTutorialTypeFilterOpen={isTutorialTypeFilterOpen}
            setFiltersToogle={setFiltersToogle}
        />
        <LanguageFilter
            selectedLanguages={props.selectedLanguages}
            setSelectedLanguages={setLanguages}
            handleSearch={handleSearch}
            isLanguageFilterOpen={isLanguageFilterOpen}
            setFiltersToogle={setFiltersToogle}
        />
        <TagFilter
            selectedTags={props.selectedTags}
            setSelectedTags={setTags}
            handleSearch={handleSearch}
            isTagFilterOpen={isTagFilterOpen}
            setFiltersToogle={setFiltersToogle}
        />
        <TechnologyFilter
            selectedTechnology={props.selectedTechnology}
            setSelectedTechnology={props.setSelectedTechnology}
            tech={props.tech}
            contentLength={props.contentLength}
            handleSearch={handleSearch}
            isTechFilterOpen={isTechFilterOpen}
            setFiltersToogle={setFiltersToogle}
        />
    </Fragment>)
}

export default Filters;
